$white: #FFFFFF;
$gray-light: darken($white, 2%);
$gray: #f5f5f5;
$gray-dark: darken($white, 5%);
$black: #000000;

$text: #323232;

$blue: #013692;
$blue-d: darken($blue, 5%);
$blue-l: lighten($blue, 5%);

$red: #e20c00;
$red-d: darken($red, 5%);
$red-l: lighten($red, 5%);

$green: #0a8b00;
$green-d: darken($green, 5%);
$green-l: lighten($green, 5%);

$yellow: #f2bc00;
$yellow-d: darken($yellow, 5%);
$yellow-l: lighten($yellow, 5%);

$violet: #9b1cca;
$violet-d: darken($violet, 5%);
$violet-l: lighten($violet, 5%);

$font: "sofia-pro",
sans-serif;

$box-shadow: 0px 0px 30px 0px rgba(56, 56, 56, 0.05);
$box-shadow-small: 0px 0px 10px 0px rgba(41, 41, 41, 0.35);
$box-shadow-large: 0px 15px 30px 0px rgba(56, 56, 56, 0.20);


* {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
}

body {
      font-family: $font;
      font-size: 16px;
      font-weight: 300;
      color: $text;
      line-height: 1.2;

      overflow: hidden;
      overflow-y: initial;

      background-color: $white !important;

      &.fixfixed {
            .footer-navigation {
                  display: none;
            }
      }

      &.admin-bar {
            .main-header {
                  .sticky-menu {
                        &.active {
                              top: 32px !important;
                        }
                  }
            }
      }
}

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl {
      max-width: 1200px;
}

hr {
      border: 1px solid $gray-dark;
}

a {
      color: $text;
      text-decoration: none;

      &:hover,
      &:focus {
            color: $blue;
      }
}

strong,
b {
      font-weight: 500;
}

h1,
h2,
h3 {

      strong,
      b {
            font-weight: 700;
      }
}

h4,
h5,
h6 {

      strong,
      b {
            font-weight: 600;
      }
}

//
// Custom global styles
//
.text-center {
      text-align: center;
}

.separator {
      display: block;
      margin: 0 auto;
      width: 75%;
      height: 2px;
      background-color: $gray-dark;
      border: none;

      &.small {
            width: 10%;
      }

      &.red {
            background-color: $red;
      }

      &.green {
            background-color: $green;
      }

      &.blue {
            background-color: $blue;
      }

      &.yellow {
            background-color: $yellow;
      }
}

ul,
ol {
      &.square {
            list-style: none;

            li {
                  position: relative;
                  padding-left: 20px;

                  &:after {
                        content: '';
                        position: absolute;
                        top: 8px;
                        left: 0;
                        width: 6px;
                        height: 6px;
                        background-color: $text;
                  }
            }

            &.blue {
                  li {
                        &:after {
                              background-color: $blue;
                        }
                  }
            }

            &.red {
                  li {
                        &:after {
                              background-color: $red;
                        }
                  }
            }

            &.green {
                  li {
                        &:after {
                              background-color: $green;
                        }
                  }
            }

            &.yellow {
                  li {
                        &:after {
                              background-color: $yellow;
                        }
                  }
            }

            &.violet {
                  li {
                        &:after {
                              background-color: $violet;
                        }
                  }
            }
      }

      &.arrowed {
            list-style: none;

            li {
                  position: relative;
                  padding-left: 32px;

                  &+li {
                        margin-top: 16px;
                  }

                  &:after {
                        font-size: 24px;
                        position: absolute;
                        top: 0px;
                        left: 0;

                        font-family: "Font Awesome 5 Free";
                        font-weight: 900;
                        content: "\f054";
                        color: $blue;

                        display: inline-block;
                        font-style: normal;
                        font-variant: normal;
                        text-rendering: auto;
                        -webkit-font-smoothing: antialiased;
                  }
            }
      }
}

.bg {
      &-gray {
            background-color: $gray;
      }

      &-blue {
            background-color: $blue;
      }

      &-red {
            background-color: $red;
      }

      &-green {
            background-color: $green;
      }

      &-yellow {
            background-color: $yellow;
      }

      &-violet {
            background-color: $violet;
      }

      &-white {
            background-color: $white;
      }
}


//
// Cookie
//
.pea_cook_wrapper {
      background-color: $red !important;
      border-radius: 24px !important;
      padding: 14px 20px !important;
      top: initial !important;
      bottom: 30px !important;
      left: 50% !important;
      transform: translateX(-50%) !important;
      margin: 0 !important;

      p {
            font-size: 14px !important;
            font-weight: 500 !important;
      }

      .pea_cook_btn {
            background-color: $white !important;
            border-radius: 32px !important;
            border: none !important;
            font-size: 14px !important;
            width: auto !important;
            text-shadow: none !important;
            padding: 8px 16px !important;
            box-shadow: none !important;
            font-family: $font !important;
      }
}

.pea_cook_control {
      margin: 16px 0 !important;
      width: 100% !important;
      background-color: $blue !important;
      color: $text;

      display: flex;
      align-items: center;
      justify-content: center;

      .eu_control_btn {
            background-color: $white !important;
            border-radius: 32px !important;
            border: none !important;
            font-size: 14px !important;
            width: auto !important;
            text-shadow: none !important;
            padding: 8px 16px !important;
            box-shadow: none !important;
            font-family: $font !important;
      }
}


//
// Main header
//
.main-header {
      background-color: $white;
      box-shadow: $box-shadow, $box-shadow;

      .top-bar {
            padding-top: 16px;
            padding-bottom: 16px;

            display: flex;
            align-items: center;
      }

      .sticky {
            &-info {
                  display: none;
                  margin: 8px 0;

                  &-inner {
                        display: flex;
                        align-items: center;
                        margin-right: -15px;

                        ul {
                              list-style: none;
                              display: flex;
                              align-items: stretch;
                              justify-content: flex-start;
                              width: 100%;

                              li {
                                    flex: 1 auto;

                                    display: flex;
                                    align-items: center;

                                    &+li {
                                          margin-left: 8px;
                                    }

                                    &:last-child {
                                          justify-content: flex-end;
                                    }

                                    a {
                                          color: $text;
                                          padding: 0;
                                    }

                                    &.icon {
                                          flex-basis: 0;
                                          flex-grow: 0;
                                          color: $blue;
                                          margin-left: 16px;
                                          margin-right: 16px;

                                          a {
                                                display: block;
                                                color: $blue;
                                          }

                                          &+li {
                                                margin-left: 0;
                                          }
                                    }

                                    &.facebook,
                                    &.instagram,
                                    &.email {
                                          flex-basis: 0;
                                          flex-grow: 0;

                                          a {
                                                color: $blue;
                                          }
                                    }

                                    &.facebook {
                                          a {
                                                font-size: 18px;
                                          }
                                    }

                                    &.instagram {
                                          a {
                                                font-size: 21px;
                                                margin-bottom: -1px;
                                          }
                                    }

                                    &.email {
                                          a {
                                                font-size: 22px;
                                          }

                                          margin-right: 16px;
                                    }

                                    &.line {
                                          position: relative;
                                          margin: 0;

                                          &:after {
                                                content: '';
                                                position: absolute;
                                                top: 50%;
                                                left: 30%;
                                                transform: translate(-50%, -50%);

                                                width: 2px;
                                                height: 75%;
                                                background-color: $gray-dark;
                                          }
                                    }
                              }
                        }
                  }
            }

            &-menu {
                  .sticky-info {
                        @media screen and (max-width:1160px) {
                              &-inner {
                                    margin-left: 0;
                                    margin-right: 0;
                              }

                              ul {
                                    justify-content: space-between;

                                    li {
                                          flex: initial;
                                          margin: 0;

                                          &.facebook,
                                          &.instagram,
                                          &.email,
                                          &:nth-child(4),
                                          &:nth-child(5) {
                                                display: none;
                                          }

                                          &:nth-child(6) {
                                                margin-left: 0;
                                          }
                                    }
                              }
                        }

                        @media screen and (max-width:870px) {
                              &-inner {
                                    margin-left: 0;
                                    margin-right: 0;
                              }

                              ul {

                                    li {

                                          &.facebook,
                                          &.instagram,
                                          &.email,
                                          &:nth-child(4),
                                          &:nth-child(5) {
                                                display: none;
                                          }

                                          &:nth-child(6) {
                                                margin-left: 0;
                                          }
                                    }
                              }
                        }

                        @media screen and (max-width:820px) {
                              &-inner {
                                    margin-left: 0;
                                    margin-right: 0;
                              }

                              ul {

                                    li {
                                          &:nth-child(6) {
                                                display: none;
                                          }
                                    }
                              }
                        }

                        @media screen and (max-width:660px) {
                              &-inner {
                                    margin-left: 0;
                                    margin-right: 0;
                              }

                              ul {

                                    li {
                                          &.line {
                                                display: none;
                                          }
                                    }
                              }
                        }

                        @media screen and (max-width:600px) {
                              display: none;
                        }
                  }

                  @media screen and (min-width:600px) {
                        &.active {
                              position: fixed;
                              top: 0;
                              left: 0;
                              width: 100%;
                              z-index: 20;

                              background-color: $white;
                              box-shadow: $box-shadow, $box-shadow;

                              .sticky-info {
                                    // display: flex;
                              }
                        }
                  }
            }
      }

      .bottom-bar {
            padding-top: 8px;
            padding-bottom: 8px;
      }

      .logo {
            display: flex;
            align-items: center;
            color: $blue;

            a {
                  display: block;
                  flex-shrink: 0;
                  width: 50%;

                  img {
                        display: block;
                        width: 100%;
                        height: auto;
                  }
            }

            &-slogan {
                  display: block;
                  font-weight: 600;
                  margin: 8px 0 0 24px;
                  font-size: 18px;

                  .break {
                        display: block;
                  }
            }
      }

      // .logo{
      //       display: flex;
      //       align-items: center;
      //       flex-direction: column;
      //       color: $text;

      //       a{
      //             display: block;
      //             flex-shrink: 0;
      //             width: 70%;

      //             img{
      //                   display: block;
      //                   width: 100%;
      //             }
      //       }

      //       &-slogan{
      //             display: block;
      //             font-weight: 600;
      //             margin: 4px 0 0 24px;
      //             font-size: 16px;
      //             text-align: center;

      //             .break{
      //                   display: block;
      //             }
      //       }
      // }

      .contact {
            &-widget {
                  display: flex;
                  align-items: center;
                  justify-content: space-around;
                  margin-left: 96px;

                  @media screen and (max-width:744px) {
                        flex-wrap: wrap;
                  }
            }

            &-box {
                  display: flex;
                  align-items: center;

                  .btn {
                        padding: 0;
                        text-align: left;
                        color: $text;

                        &:hover,
                        &:focus,
                        &.active {
                              color: $blue;
                        }
                  }

                  .icon {
                        color: $blue;
                  }

                  &.list {
                        flex-direction: column;
                        align-items: flex-start;
                  }

                  &:not(.list) {
                        .icon {
                              font-size: 28px;
                              margin-right: 16px;
                        }
                  }

                  &.socials {
                        flex: 1 auto;

                        a {
                              &+a {
                                    margin-left: 14px;
                              }

                              &:nth-child(1) {
                                    .icon {
                                          font-size: 26px;
                                    }
                              }

                              &:nth-child(2) {
                                    .icon {
                                          font-size: 30px;
                                    }
                              }

                              &:nth-child(3) {
                                    .icon {
                                          font-size: 32px;
                                    }
                              }
                        }

                        @media screen and (max-width:744px) {
                              display: none;
                        }
                  }
            }

            &-icon {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: 26px;
                  margin-right: 16px;
            }

            &-row {
                  display: flex;
                  align-items: flex-start;
                  flex-direction: column;
            }
      }

      .main-menu {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;

            ul {
                  display: flex;
                  align-items: center;
                  list-style: none;

                  &.main-menu-list {
                        .btn {
                              border: none;
                        }
                  }

                  &.main-menu-ctas {
                        li {
                              &+li {
                                    margin-left: 8px;
                              }
                        }
                  }

                  li {
                        &.menu-item-has-children {
                              position: relative;

                              .sub-menu {
                                    position: absolute;
                                    bottom: -8px;
                                    transform: translateY(110%);
                                    background-color: $white;
                                    box-shadow: $box-shadow;
                                    z-index: 10;

                                    display: flex;
                                    flex-direction: column;
                                    align-items: flex-start;

                                    padding: 16px;

                                    visibility: hidden;
                                    opacity: 0;

                                    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out, transform 0.3s ease-in-out;

                                    li {
                                          width: 100%;

                                          a {
                                                display: block;
                                                width: 100%;
                                                text-align: left;
                                          }
                                    }
                              }

                              &.active {
                                    .sub-menu {
                                          visibility: visible;
                                          opacity: 1;
                                          transform: translateY(100%);

                                          li {
                                                display: block;
                                          }
                                    }

                                    &>a {
                                          &:after {
                                                transform: translate(0, -50%) rotate(-180deg);
                                          }
                                    }
                              }

                              &>a {
                                    position: relative;
                                    padding-right: 2rem;

                                    &:after {
                                          position: absolute;
                                          top: 50%;
                                          right: 0.5rem;
                                          transform: translate(0, -50%) rotate(0);

                                          font-family: "Font Awesome 5 Free";
                                          font-weight: 900;
                                          content: "\f107";

                                          transition: transform 0.3s ease-in-out;
                                    }
                              }
                        }
                  }
            }

            .btn {
                  font-size: 18px;

                  &.btn-lg {
                        font-size: 20px;
                        // padding: 0.65rem 1rem;
                  }

                  &.btn-sm {
                        font-size: 14px;
                        // padding: 0.35rem 1rem;
                  }
            }

            &-search {
                  position: absolute;
                  top: -8px;
                  left: -8px;
                  width: 100%;
                  height: calc(100% + 16px);
                  width: calc(100% + 16px);
                  padding: 8px;
                  // transform: translateY(-50%);
                  background-color: $white;

                  display: none;

                  &.show {
                        display: flex;
                        align-items: center;
                  }

                  form {
                        display: flex;
                        width: 100%;

                        input {
                              flex: 1;
                        }

                        input[type="search"] {
                              -webkit-appearance: none;
                              outline: none;
                        }

                        input[type="search"]:focus {
                              border-color: $blue;
                        }

                        .btn {
                              &:first-child {
                                    border-radius: 64px 0 0 64px;
                              }

                              &:last-child {
                                    border-radius: 0 64px 64px 0;
                              }
                        }
                  }
            }
      }

      //
      // RWD
      //
      @media screen and (max-width:1160px) {
            .main-menu {
                  flex-direction: column;

                  ul+ul {
                        margin-top: 16px;
                  }
            }

            .bottom-bar {
                  padding-top: 16px;
                  padding-bottom: 16px;
            }

            .contact-widget {
                  .contact-box.socials {
                        display: none;
                  }
            }
      }

      @media screen and (max-width:1070px) {
            .top-bar {
                  // flex-direction: column-reverse;
                  display: flex;
                  align-items: center;

                  .logo {
                        flex-direction: column;

                        a {
                              width: 200px;
                        }

                        &-slogan {
                              margin: 16px 0 0 0;

                              .break {
                                    display: inline-block;
                                    margin: 0 3px;
                              }
                        }
                  }
            }

            .contact-widget {
                  margin-left: 0;
            }
      }

      @media screen and (max-width:600px) {
            .bottom-bar {
                  padding: 0;

                  .menu-mobile {
                        display: block;
                  }

                  .main-menu {
                        display: none;
                        padding-top: 16px;
                        position: fixed;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        max-height: 100vh;
                        z-index: 11;
                        background-color: $white;

                        padding: 0 16px 24px 16px;

                        align-items: flex-end;
                        justify-content: center;

                        transform-origin: bottom;

                        overflow: hidden;
                        overflow-y: auto;

                        ul {
                              width: 100%;
                              flex-direction: column;

                              li {
                                    width: 100%;
                                    text-align: center;

                                    a,
                                    .btn {
                                          width: 100%;
                                          white-space: initial;
                                    }

                                    &+li {
                                          margin: 16px 0 0 0;
                                    }

                                    &.menu-item-has-children {
                                          position: relative;

                                          .sub-menu {
                                                width: 100%;
                                          }
                                    }
                              }

                              &.main-menu-list {

                                    li {

                                          &:first-child,
                                          &:last-child {
                                                display: none;
                                          }
                                    }
                              }
                        }

                        .main-menu-search {
                              width: 100%;
                              height: 100%;
                              left: 0;

                              align-items: flex-end;
                        }
                  }
            }
      }

      @media screen and (max-width:1214px) {
            .contact-widget {
                  margin-left: 0;

                  .contact-box {
                        padding: 16px 0 16px 16px;
                  }
            }
      }

      @media screen and (max-width:744px) {
            .contact-widget {
                  .contact-box {
                        justify-content: center;

                        &:first-child {
                              display: none;
                        }
                  }
            }
      }

      @media screen and (max-width:440px) {
            .contact-widget {

                  .contact-box {
                        width: 100%;
                        padding-right: 0;

                        flex-direction: column;

                        text-align: center;

                        .icon {
                              margin: 0;
                        }
                  }
            }

            .main-menu {
                  &-search {
                        position: absolute;
                        top: 0;
                        left: -8px;
                        height: calc(100% + 16px);
                        width: calc(100% + 16px);
                        display: none;

                        &.show {
                              display: flex;
                              align-items: center;
                        }

                        form {
                              flex-wrap: wrap;

                              input {
                                    flex: 1 1 auto;
                                    border-radius: 0 16px 0 0;
                              }

                              .btn {
                                    &:first-child {
                                          border-radius: 16px 0 0 0;
                                    }

                                    &:last-child {
                                          border-radius: 0 0 16px 16px;
                                          width: 100%;
                                    }
                              }
                        }
                  }
            }
      }
}


//
// Sticky contact
//
.sticky-contact {
      position: fixed;
      top: 33%;
      right: 0;
      z-index: 10;

      ul {
            list-style: none;

            li {
                  transform: translateX(100%);

                  transition: transform 0.2s ease-in-out;
                  will-change: transform;

                  &:nth-child(1) {
                        transition-delay: 0.025s;
                  }

                  &:nth-child(2) {
                        transition-delay: 0.05s;
                  }

                  &:nth-child(3) {
                        transition-delay: 0.075s;
                  }

                  &:nth-child(4) {
                        transition-delay: 0.1s;
                  }

                  &:nth-child(5) {
                        transition-delay: 0.125s;
                  }

                  .btn {
                        width: 100%;
                        border-radius: 64px 0 0 64px;
                        padding: 0.5rem 0.5rem 0.5rem 1rem;
                        box-shadow: $box-shadow-small;
                  }

                  &+li {
                        margin-top: 8px;
                  }
            }
      }

      &.show {
            ul {
                  li {
                        transform: translateX(0);
                  }
            }
      }

      //
      // RWD
      //
      @media screen and (max-width:600px) {
            top: 35%;
            transform: translateY(-50%);
      }
}


//
// Section
//
.section {
      $this: &;
      padding: 64px 0;

      &-title {
            font-size: 50px;
            text-align: center;
            display: block;
            width: 100%;
            margin-bottom: 64px;

            &+&,
            &+#{$this}-subtitle {
                  margin-top: -64px;
            }
      }

      &-subtitle {
            font-size: 40px;
            text-align: center;
            display: block;
            width: 100%;
            margin-bottom: 64px;

            &+& {
                  margin-top: -64px;
            }
      }
}


//
// Main slider
//
.offer-slider {
      $this: &;
      padding: 32px 0;

      .slick-slider {
            .slick-list {
                  width: calc(100% + 64px) !important;

                  padding: 32px !important;
                  margin-left: -32px !important;

                  display: flex;
                  align-items: center;

                  //
                  // RWD
                  //
                  @media screen and (max-width:1286px) {
                        width: calc(100% + 30px) !important;
                        padding: 0 !important;
                        margin-left: -15px !important;
                  }
            }

            .slick-track {
                  width: 100%;

                  display: flex;
                  align-items: center;

                  .slick-slide {
                        opacity: 0;
                        pointer-events: none;
                        transition: opacity 0.4s ease;

                        &.slick-active {
                              opacity: 1;
                              pointer-events: initial;
                        }

                        &.first {
                              #{$this}-bg {
                                    border-radius: 10px 0 0 10px;
                              }
                        }

                        &.last {
                              #{$this}-bg {
                                    border-radius: 0 10px 10px 0;
                              }
                        }
                  }
            }
      }

      .slick-dots {
            bottom: -30px;

            li {
                  width: initial;
                  height: initial;

                  button {
                        width: 30px;
                        height: 8px;
                        background-color: $text;
                        border-radius: 8px;
                        opacity: 0.1;

                        &:before {
                              display: none;
                        }
                  }

                  &.slick-active {
                        &.blue {
                              button {
                                    background-color: $blue;
                              }
                        }

                        &.red {
                              button {
                                    background-color: $red;
                              }
                        }

                        &.green {
                              button {
                                    background-color: $green;
                              }
                        }

                        &.yellow {
                              button {
                                    background-color: $yellow;
                              }
                        }

                        &.violet {
                              button {
                                    background-color: $violet;
                              }
                        }

                        button {
                              opacity: 1;
                        }
                  }
            }
      }

      &-item {
            position: relative;
            padding: 24px;
            text-align: center;
            min-height: 320px;
            display: flex !important;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 25%;
            flex-shrink: 0;

            text-decoration: none;
            color: $text;

            &.active {
                  color: $white;

                  #{$this}-bg {
                        transform: scale(1.15);
                        z-index: 1;
                        border-radius: 15px 15px 15px 15px !important;
                  }
            }

            &.blue {
                  #{$this}-bg {
                        border-bottom: 4px solid $blue;
                  }

                  &.active {

                        #{$this}-bg {
                              background-color: $blue;
                        }
                  }
            }

            &.red {
                  #{$this}-bg {
                        border-bottom: 4px solid $red;
                  }

                  &.active {

                        #{$this}-bg {
                              background-color: $red;
                        }
                  }
            }

            &.green {
                  #{$this}-bg {
                        border-bottom: 4px solid $green;
                  }

                  &.active {

                        #{$this}-bg {
                              background-color: $green;
                        }
                  }
            }

            &.yellow {
                  #{$this}-bg {
                        border-bottom: 4px solid $yellow;
                  }

                  &.active {

                        #{$this}-bg {
                              background-color: $yellow;
                        }
                  }
            }

            &.violet {
                  #{$this}-bg {
                        border-bottom: 4px solid $violet;
                  }

                  &.active {

                        #{$this}-bg {
                              background-color: $violet;
                        }
                  }
            }
      }

      &-link {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 3;
      }

      &-title {
            position: relative;
            z-index: 2;
            font-size: 30px;
            font-weight: 600;
            // margin-bottom: 24px;

            transition: color 0.2s ease;
      }

      &-content {
            position: relative;
            z-index: 2;
            display: none;

            transition: color 0.2s ease;

            &>* {
                  margin-top: 24px;
            }
      }

      &-bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $gray-dark;
            z-index: -1;
            overflow: hidden;

            transition: transform 0.4s ease, background 0.4s ease, border-radius 0.4s ease;

            pointer-events: none;

            &-image {
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  filter: grayscale(100%);
                  opacity: 0.35;

                  background-size: cover;
                  background-position: center;

                  transition: opacity 0.4s ease;
            }
      }

      @media screen and (max-width:984px) {
            padding: 0 0 32px 0;
      }
}


//
// Hero
//
$hero_offset: 130;

.hero {
      $this: &;
      margin-bottom: - $hero_offset + px;
      position: relative;
      z-index: 10;

      &+.section {
            padding-top: $hero_offset + 64 + px;
      }

      &-inner {
            background-color: $gray;
            padding: 64px;
            border-radius: 15px;
            position: relative;
            z-index: 1;

            //
            // RWD
            //
            @media screen and (max-width:992px) {
                  padding: 24px !important;
            }


            .row {
                  align-items: center;
            }

            h3 {
                  font-size: 40px;
                  margin-bottom: 24px;

                  //
                  // RWD
                  //
                  @media screen and (max-width:992px) {
                        text-align: center;
                  }
            }

            p {
                  font-size: 20px;

                  //
                  // RWD
                  //
                  @media screen and (max-width:992px) {
                        text-align: center !important;
                  }
            }

            .btn {
                  margin-top: 24px;

                  //
                  // RWD
                  //
                  @media screen and (max-width:992px) {
                        text-align: center;
                  }
            }

            img {
                  display: block;
                  width: 100%;

                  //
                  // RWD
                  //
                  @media screen and (max-width:992px) {
                        display: inline-block;
                        text-align: center;
                  }
            }
      }

      &-title {
            display: flex;
            align-items: center;
            margin-bottom: 24px;

            i {
                  font-size: 64px;
                  margin-right: 16px;
            }

            h3 {
                  margin: 0;
            }
      }

      &-cta {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 24px;

            p {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin: 0 16px;

                  .btn {
                        margin-top: 0;
                  }
            }
      }

      // Other types
      &.truck {
            #{$this}-inner {
                  img {
                        position: absolute;
                        top: 50%;
                        left: 100%;
                        transform: translate(-80%, -50%);
                        width: 120%;

                        //
                        // RWD
                        //
                        @media screen and (max-width:992px) {
                              position: relative;
                              top: 0;
                              left: 0;
                              transform: translate(0, 0);
                              width: 100%;
                              margin-top: 24px;
                        }
                  }
            }
      }

      &.newsletter {

            [class*="col-"] {
                  position: initial;
            }

            #{$this}-inner {
                  padding: 48px 64px;
            }

            h3 {
                  small {
                        font-weight: 500;
                  }
            }

            p {
                  text-align: right;
            }

            .btn {
                  margin: 0;

                  &:after {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                  }
            }
      }

      &.error {
            margin: $hero_offset + px 0;
      }

      &.service-specialty {
            margin-bottom: - $hero_offset + px;

            #{$this}-inner {
                  background-image: url('../img/hero_bg_1.jpg');
                  background-repeat: no-repeat;
                  background-size: cover;
                  color: $white;
                  justify-content: flex-end;
                  text-align: right;
            }

            &.cpk {
                  margin-top: -32px;
                  margin-bottom: 0;

                  &+.section.trust {
                        margin-top: -64px;
                  }

                  &+.map {
                        margin-top: 128px;
                  }

                  &+.footer {
                        margin-top: 64px;
                  }
            }

            //
            // RWD
            //
            @media screen and (max-width:820px) {
                  #{$this}-inner {
                        background-position: 40% 0;

                        // text-shadow: 0 0 10px rgba(20, 20, 20, 0.75);

                        &:after {
                              content: '';
                              position: absolute;
                              top: 0;
                              left: 0;
                              width: 100%;
                              height: 100%;
                              background-color: $blue;
                              opacity: 0.5;
                              z-index: -1;
                        }
                  }
            }
      }

      &.service-cpk-banner {
            #{$this}-inner {
                  background-image: url('../img/hero_bg_2.jpg');
                  background-repeat: no-repeat;
                  background-size: cover;
                  background-position: center;
                  color: $text;
                  justify-content: flex-end;
                  text-align: right;

                  box-shadow: $box-shadow-large;

                  padding: 32px 64px;

                  h3 {
                        font-weight: 300;
                        margin-bottom: 0;
                  }

                  span {
                        color: $green;
                        font-weight: 600;
                        font-size: 72px;
                        display: inline-block;
                        vertical-align: sub;
                  }
            }

            //
            // RWD
            //
            @media screen and (max-width:820px) {
                  #{$this}-inner {
                        &:after {
                              background-color: $white;
                              opacity: 0.65;
                        }
                  }
            }
      }

      &.newsletterpage {
            #{$this}-inner {
                  background-color: $white;

                  a:not(.btn) {
                        border: 2px solid $blue;
                        color: $blue;
                        cursor: pointer;
                        display: inline-block;
                        font-size: 16px;
                        // outline: 0;
                        padding: 0.4rem 1rem 0.5rem 1rem;
                        text-decoration: none;
                        text-align: center;
                        transition: background .2s ease, border .2s ease, box-shadow .2s ease, color .2s ease;
                        vertical-align: middle;
                        white-space: nowrap;
                        border-radius: 64px;
                        font-weight: 600;
                        background-color: transparent;

                        background-color: $red;
                        border-color: $red;
                        color: $white;

                        &:hover,
                        &:focus,
                        &.active {
                              background-color: lighten($red, 10%);
                              border-color: lighten($red, 10%);
                        }
                  }

                  .tnp-profile+p+p a:not(.btn) {
                        margin-top: 24px;
                  }
            }
      }

      $card_reader_offset: 32;

      &.card-reader {
            padding: 16px 0 96px 0;
            margin: 0;
            background-color: $gray;

            #{$this}-inner {
                  background-color: $green;
                  color: $white;

                  padding: 32px 0 32px 64px;

                  overflow: initial;

                  p+p {
                        margin-top: 16px;
                  }

                  img {
                        border-radius: 10px;
                        margin: - 32 + - $card_reader_offset + px 0;
                        box-shadow: $box-shadow-small;
                  }
            }

            &+.about-more {
                  padding: 64px 0;
            }

            &.bg-half {
                  background-color: $white;

                  &:after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        height: 75%;
                        background-color: $gray;
                  }
            }

            @media screen and (max-width:1080px) {
                  #{$this}-inner {
                        padding: 32px;

                        img {
                              margin: 0;
                        }
                  }
            }

            @media screen and (max-width:992px) {
                  #{$this}-inner {
                        .hero-title {
                              justify-content: center;
                        }

                        img {
                              margin: 32px 0 0 0;
                        }
                  }
            }

            @media screen and (max-width:992px) {
                  #{$this}-inner {
                        .hero-title {
                              justify-content: center;
                              align-items: center;
                              flex-direction: column;
                        }
                  }
            }
      }

      &.training {
            #{$this}-inner {
                  background-color: $red;
                  color: $white;

                  .price {
                        font-weight: 600;
                        font-size: 28px;
                  }
            }
      }
}


//
// Offer
//
.offer {
      $this: &;

      h3 {
            &#{$this}-title {
                  font-weight: 600;
                  font-size: 38px;
                  line-height: 1.4;

                  i {
                        display: inline-block;
                        font-size: 40px;
                        margin-bottom: 16px;
                  }
            }

            //
            // RWD
            //
            @media screen and (max-width:992px) {
                  text-align: center;
                  margin-bottom: 16px;
            }
      }

      p {
            &#{$this}-title {
                  font-size: 24px;
                  font-weight: 600;

                  .blink {
                        padding: 0 8px;
                        line-height: 0;
                        color: $red;
                        background-color: transparent;

                        animation: blinktext 1.25s steps(1, end) infinite forwards;
                  }

                  //
                  // RWD
                  //
                  @media screen and (max-width:992px) {
                        text-align: center;
                  }
            }
      }

      &-line {
            margin: 16px 0;
            display: block;
            width: 64px;

            //
            // RWD
            //
            @media screen and (max-width:992px) {
                  margin: 16px auto;
            }
      }

      &-content {
            font-weight: 100;
            line-height: 1.4;

            display: flex;
            flex-direction: column;

            ul {
                  list-style: none;

                  &:not(.square) {
                        display: flex;
                        flex-direction: column;
                        // align-items: center;
                  }

                  li {
                        &+li {
                              margin-top: 8px;
                        }

                        a {
                              display: inline-block;
                              // text-align: center;
                        }
                  }

                  img {
                        display: inline-block;
                        width: 16px;
                        height: 16px;
                        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
                        border-radius: 50%;
                        margin-right: 16px;
                  }
            }

            [class|="col"] {
                  display: flex;
                  flex-direction: column;
            }
      }

      &-cta {
            align-self: flex-end;

            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: auto;

            p {
                  font-weight: 600;
                  font-size: 24px;

                  &+.btn {
                        margin-left: 24px;
                  }
            }

            //
            // RWD
            //
            @media screen and (max-width:992px) {
                  align-self: center;
                  flex-direction: column;
                  margin-top: 24px;

                  p {

                        &+.btn {
                              margin-left: 0;
                              margin-top: 16px;
                        }
                  }
            }
      }

      .row {
            &+.row {
                  margin-top: 32px;
            }
      }

      &-box {
            position: relative;
            width: 100%;

            &+& {
                  margin-top: 64px;

                  &:before {
                        content: '';
                        position: absolute;
                        top: -32px;
                        right: 0;
                        width: 75%;
                        height: 2px;
                        background-color: $gray-dark;

                        //
                        // RWD
                        //
                        @media screen and (max-width:992px) {
                              width: 100%;
                        }
                  }
            }
      }

      &-blue {
            h3 {
                  &#{$this}-title {
                        color: $blue;
                  }
            }

            #{$this}-line {
                  border-color: $blue;
            }

            #{$this}-cta {
                  p {
                        color: $blue;
                  }
            }

            #{$this}-content {
                  ul {
                        &.square {
                              li {
                                    &:after {
                                          background-color: $blue;
                                    }
                              }
                        }
                  }
            }
      }

      &-green {
            h3 {
                  &#{$this}-title {
                        color: $green;
                  }
            }

            #{$this}-line {
                  border-color: $green;
            }

            #{$this}-cta {
                  p {
                        color: $green;
                  }
            }

            #{$this}-content {
                  ul {
                        &.square {
                              li {
                                    &:after {
                                          background-color: $green;
                                    }
                              }
                        }
                  }
            }
      }

      &-red {
            h3 {
                  &#{$this}-title {
                        color: $red;
                  }
            }

            #{$this}-line {
                  border-color: $red;
            }

            #{$this}-cta {
                  p {
                        color: $red;
                  }
            }

            #{$this}-content {
                  ul {
                        &.square {
                              li {
                                    &:after {
                                          background-color: $red;
                                    }
                              }
                        }
                  }
            }
      }

      &-yellow {
            h3 {
                  &#{$this}-title {
                        color: $yellow;
                  }
            }

            #{$this}-line {
                  border-color: $yellow;
            }

            #{$this}-cta {
                  p {
                        color: $yellow;
                  }
            }

            #{$this}-content {
                  ul {
                        &.square {
                              li {
                                    &:after {
                                          background-color: $yellow;
                                    }
                              }
                        }
                  }
            }
      }

      &-violet {
            h3 {
                  &#{$this}-title {
                        color: $violet;
                  }
            }

            #{$this}-line {
                  border-color: $violet;
            }

            #{$this}-content {
                  ul {
                        li {
                              &+li {
                                    margin-top: 24px;
                              }

                              a {
                                    font-size: 18px;
                                    font-weight: 600;
                              }
                        }

                        &.square {
                              li {
                                    &:after {
                                          background-color: $blue;
                                    }
                              }
                        }
                  }
            }

            #{$this}-cta {
                  p {
                        color: $violet;
                  }
            }
      }

      //
      // RWD
      //
      @media screen and (max-width:992px) {
            &-box {
                  &>.row {
                        flex-direction: column;
                  }
            }

            &-title {
                  &-wrapper {
                        width: 100%;
                        max-width: 100%;
                        flex: 0 0 100%;

                        justify-content: center;
                        align-items: center;
                  }
            }

            &-blocks {
                  width: 100%;
                  max-width: 100%;
                  flex: 0 0 100%;

                  .row {
                        flex-direction: column;

                        &>div {
                              width: 100%;
                              max-width: 100%;
                              flex: 0 0 100%;
                        }
                  }
            }

            &-content {
                  text-align: center;
                  padding: 0 0 16px 0;

                  ul {
                        margin-bottom: 8px;
                        text-align: left;

                        &:not(.square) {
                              align-items: center;
                        }

                        li {
                              text-align: center;

                              a {
                                    text-align: center;
                              }
                        }
                  }
            }
      }
}

@keyframes blinktext {

      0%,
      100% {
            background-color: transparent;
            color: $red;
      }

      50% {
            background-color: $red;
            color: $white;
      }
}


//
// Main page - News
//
$articleheight: 200;

.news {
      $this: &;
      padding-bottom: 0;
      overflow: hidden;
      position: relative;

      &-row {
            background-color: $gray;
            margin-top: $articleheight + px;
      }

      &-actions {
            text-align: center;
            padding: 64px 0 64px 0;
            margin-top: -24px;
      }

      .row {
            &:nth-child(1) {
                  [class|="col"] {
                        margin-bottom: 24px;

                        &:nth-child(1),
                        &:nth-child(2),
                        &:nth-child(3) {
                              margin-top: - $articleheight + px;
                        }
                  }
            }
      }

      &-box {
            box-shadow: 0px 6px 15px 0px rgba(0, 0, 0, 0.08);
            border-radius: 15px;
            overflow: hidden;
            height: 100%;

            display: flex;
            flex-direction: column;

            &:after {
                  content: '';
                  position: absolute;
                  top: 0;
                  left: 15px;
                  right: 15px;
                  height: 100%;
                  box-shadow: 0px 6px 15px 0px rgba(0, 0, 0, 0.2);
                  border-radius: 15px;

                  opacity: 0;
                  transition: opacity 0.4s ease;

                  z-index: 0;
            }

            &:hover,
            &:focus,
            &:focus-within {
                  &:after {
                        opacity: 1;
                  }
            }
      }

      &-header {
            height: $articleheight + px;
            background-size: cover;
            background-position: center;

            display: flex;
            align-items: flex-end;
            padding: 16px 24px;
            color: $white;
            font-size: 20px;

            position: relative;

            text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);

            p {
                  position: relative;
                  z-index: 1;
            }

            &:after {
                  content: '';
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  width: 100%;
                  height: 40%;
                  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.80), rgba(0, 0, 0, 0));
            }
      }

      &-content {
            background-color: $white;
            padding: 16px 24px;

            display: flex;
            flex-direction: column;
            align-items: flex-start;
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: auto;

            .btn {
                  &:after {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 15px;
                        right: 15px;
                        height: 100%;
                        z-index: 1;
                  }
            }
      }

      &-title {
            margin-bottom: 16px;
      }

      &-desc {
            margin-bottom: 16px;
            line-height: 1.4;
      }

      .btn {
            margin-top: auto;
            align-self: end;
            margin-left: auto;
      }

      &-page {
            .row {
                  &:nth-child(1) {
                        [class|="col"] {
                              margin-bottom: 64px;

                              &:nth-child(1),
                              &:nth-child(2),
                              &:nth-child(3) {
                                    margin-top: 0;
                              }
                        }
                  }
            }

            #{$this}-row {
                  background-color: $white;
                  margin: 0;

                  &+#{$this}-row {
                        background-color: $gray;
                        margin-top: $articleheight + px;

                        .row {
                              &:first-child {
                                    [class|="col"] {
                                          margin-bottom: 64px;

                                          &:nth-child(1),
                                          &:nth-child(2),
                                          &:nth-child(3) {
                                                margin-top: - $articleheight + px;
                                          }
                                    }
                              }
                        }
                  }
            }

            .news-actions {
                  padding-top: 32px;
            }

            &.empty {
                  #{$this}-row {
                        &+#{$this}-row {
                              background-color: $white;
                        }
                  }
            }
      }

      &-pagination {
            ul {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  list-style: none;

                  li {
                        width: 44px;
                        height: 44px;
                        margin: 0 4px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-align: center;

                        span,
                        a {
                              width: 36px;
                              height: 36px;
                              padding: 0;

                              background-color: $white;
                              box-shadow: $box-shadow;
                              border-radius: 50%;

                              display: flex;
                              align-items: center;
                              justify-content: center;
                              text-align: center;

                              line-height: 1;

                              transition: background 0.4s ease, color 0.4s ease;

                              &:hover,
                              &:focus {
                                    background-color: $blue;
                                    color: $white;
                              }

                              &.current {
                                    width: 44px;
                                    height: 44px;
                                    background-color: $blue;
                                    color: $white;
                                    font-size: 20px;
                              }

                              &.empty {
                                    box-shadow: none;
                                    background-color: transparent;
                              }
                        }

                        &.dotted {
                              border-radius: 64px;
                              width: 96px;
                        }

                        .page-numbers {
                              &.dots {
                                    position: relative;
                                    overflow: hidden;

                                    border-radius: 64px;
                                    width: 96px;

                                    input {
                                          position: absolute;
                                          top: 0;
                                          left: 0;
                                          width: 100%;
                                          height: 100%;

                                          border: none;
                                          text-align: center;

                                          font-size: 16px;
                                          font-family: $font;

                                          background-color: $white;
                                          transition: background 0.2s ease-in-out, color 0.2s ease-in-out;

                                          &:hover,
                                          &:focus {
                                                background-color: $blue;
                                                color: $white;
                                          }
                                    }
                              }
                        }
                  }
            }
      }

      @media screen and (max-width:768px) {
            .row {
                  &:nth-child(1) {
                        [class|="col"] {
                              margin-bottom: 24px;

                              &:nth-child(1) {
                                    margin-top: - $articleheight + px;
                              }

                              &:nth-child(2),
                              &:nth-child(3) {
                                    margin-top: 0px;
                              }
                        }
                  }
            }

            &-page {
                  .row {
                        &:nth-child(1) {
                              [class|="col"] {
                                    margin-bottom: 24px;

                                    &:nth-child(1),
                                    &:nth-child(2),
                                    &:nth-child(3) {
                                          margin-top: 0;
                                    }
                              }
                        }
                  }

                  #{$this}-row {
                        background-color: $white;
                        margin: 0;

                        &+#{$this}-row {
                              background-color: $gray;
                              margin-top: $articleheight + px;

                              .row {
                                    &:first-child {
                                          [class|="col"] {
                                                margin-bottom: 24px;

                                                &:nth-child(1) {
                                                      margin-top: - $articleheight + px;
                                                }

                                                &:nth-child(2),
                                                &:nth-child(3) {
                                                      margin-top: 0px;
                                                }
                                          }
                                    }
                              }
                        }
                  }
            }
      }
}


//
// Trusty
//
.trust {
      $this: &;
      position: relative;

      display: flex;
      align-items: stretch;
      justify-content: center;

      padding-bottom: 0;

      overflow: hidden;

      @media screen and (max-width:986px) {
            flex-direction: column;
            padding: 0;
      }

      .section-title {
            font-size: 40px;
            color: $blue;
            margin: 0;
            font-weight: 600;
      }

      &-left,
      &-right {
            width: 100%;
            max-width: 600px;
            flex: 0 0 100%;

            @media screen and (max-width:1286px) {
                  flex: initial;
            }

            @media screen and (max-width:986px) {
                  max-width: 100%;
                  padding: 32px 0;
            }
      }

      &-left {
            margin-left: auto;

            position: relative;

            #{$this}-bg {
                  position: absolute;
                  bottom: 0;
                  right: 0;
                  width: 200%;
                  height: calc(100% - 160px);
                  z-index: -1;
                  background-color: $blue;
                  border-radius: 0 15px 0 0;

                  &:after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        width: 100%;
                        height: 100%;
                        background-image: url("../img/bg1.jpg");
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: cover;
                        opacity: 0.05;
                        z-index: 1;
                  }

                  @media screen and (max-width:986px) {
                        bottom: 0;
                        width: 100%;
                        height: calc(100% - 192px);
                        border-radius: 0;
                  }
            }
      }

      &-right {
            margin-right: auto;
      }

      &-content {
            padding: 0 32px 64px 32px;

            img {
                  display: block;
                  width: 100%;
            }

            &.widget {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
            }
      }

      &-grid {
            display: flex;
            flex-wrap: wrap;
            position: relative;
            color: $white;
            margin-top: 64px;
      }

      &-box {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            margin-bottom: 64px;

            width: 50%;

            &:nth-child(3),
            &:nth-child(4) {
                  margin-bottom: 0;
            }

            @media screen and (max-width:346px) {
                  width: 100%;

                  margin-bottom: 64px;

                  &:nth-child(3) {
                        margin-bottom: 64px;
                  }

                  &:nth-child(4) {
                        margin-bottom: 0;
                  }
            }
      }

      &-ico {
            font-size: 50px;
            margin-bottom: 4px;
      }

      &-number {
            font-size: 48px;
            font-weight: 600;
            margin-bottom: 4px;
      }

      &-text {
            font-size: 20px;
            font-weight: 100;
      }

      @media screen and (max-width:986px) {
            &-content {
                  &:first-child {
                        padding-bottom: 8px;
                  }
            }

            &-left {
                  .section-title {
                        color: $white;
                  }

                  .trust-bg {
                        height: 100%;
                  }
            }
      }
}


//
// Szkolenia okresowe
//
.training {
      padding: 32px 0;

      &-scheme {
            // &-about{

            // }

            &-schedule {
                  margin-top: 24px;

                  &>*+* {
                        margin-top: 16px;
                  }
            }
      }
}

.detailed-information {
      background-color: $gray;
      padding-bottom: 128px;

      &-content {
            p {
                  font-size: 18px;

                  &+p {
                        margin-top: 16px;
                  }

                  small {
                        font-size: 16px;
                  }
            }
      }
}


//
// Events
//
.events-list {
      display: flex;
      flex-direction: column;

      background-color: $white;

      border: 1px solid $gray-dark;
      border-radius: 5px;
      overflow: hidden;
      margin-bottom: 24px;

      &+.btn {}

      .event {
            &-item {
                  position: relative;
                  display: flex;
                  align-items: stretch;
                  flex-direction: row;
                  border-left-width: 4px;
                  border-left-style: solid;
                  transition: all 0.2s ease-in-out;

                  &+.event-item {
                        border-top: 1px solid $gray-dark;
                  }

                  &.clickable {

                        &:hover,
                        &:focus {
                              border-left-width: 8px;
                        }
                  }
            }

            &-date,
            &-info {
                  padding: 16px;

                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  justify-content: flex-start;
                  text-align: left;
            }

            &-date {

                  width: auto;
                  flex-shrink: 0;

                  span {
                        line-height: 1;

                        &:nth-child(1) {
                              font-size: 20px;
                              font-weight: 600;
                        }

                        &:nth-child(2) {
                              font-size: 14px;
                              font-weight: 200;
                        }
                  }
            }

            &-info {
                  padding-left: 0;
                  width: 100%;

                  opacity: 0.8;
                  transition: opacity 0.2s ease-in-out;
            }

            &-name {
                  text-transform: uppercase;
                  font-weight: 600;
                  font-size: 18px;
            }

            &-about {
                  font-size: 14px;
            }

            &-link {
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  opacity: 0;
                  z-index: 1;

                  &:hover,
                  &:focus {
                        &+.event-date {
                              &+.event-info {
                                    opacity: 1;
                              }
                        }
                  }
            }
      }

      &-grid {
            border: none;
            margin-left: -8px;
            margin-right: -8px;
            margin-bottom: 96px;

            display: grid;
            grid-template-columns: repeat(3, 1fr);

            .event-item {
                  border: 2px solid $gray-dark;
                  margin: 8px;
                  border-radius: 5px;
                  border-left-width: 4px;

                  &+.event-item {
                        border-top: 2px solid $gray-dark;
                  }
            }

            @media screen and (max-width:1070px) {
                  grid-template-columns: repeat(3, 1fr);
            }

            @media screen and (max-width:864px) {
                  grid-template-columns: repeat(2, 1fr);
            }

            @media screen and (max-width:640px) {
                  grid-template-columns: repeat(1, 1fr);
            }
      }
}


//
// Map
//
$marksize: 52;
$mark_d: $marksize;
$mark_a: $mark_d / 1.41421356237;

.map {
      height: 500px;
      background-color: $gray;
      position: relative;
      z-index: 1;

      &-object {
            height: 500px;
      }

      .marker {
            width: 64px;
            height: 64px;

            &.reader,
            &.office {
                  &:after {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        right: 0;
                        bottom: 0;

                        background-color: $red;
                        width: $marksize + px;
                        height: $marksize + px;
                        border-radius: 50% 50% 50% 0;
                        transform: translate(-50%, -50%) rotate(-45deg);
                        cursor: pointer;
                        // transform-origin: bottom left;
                  }

                  &:before {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);

                        font-family: "Font Awesome 5 Free";
                        display: inline-block;
                        font-style: normal;
                        font-variant: normal;
                        text-rendering: auto;
                        -webkit-font-smoothing: antialiased;
                        z-index: 1;
                        font-size: 24px;
                        color: #FFFFFF;
                        font-weight: 400;
                  }
            }

            &.reader {
                  &:before {
                        content: '\f2c2';
                        // transform: translate(-50%, -230%);
                  }
            }

            &.office {
                  &:before {
                        content: '';
                        width: $marksize + px;
                        height: $marksize + px;
                        background-image: url('../img/star_white.png');
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: 32px;
                        // transform: translate(-50%, -115%);
                  }

                  .office-logo {
                        position: absolute;
                        top: 50%;
                        left: 100%;
                        width: 160px;
                        height: 30px;
                        background-image: url('../img/logo_simple.png');
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: contain;

                        transform: translate(0, -50%);
                        z-index: 2;

                        &:after {
                              content: '';
                              position: absolute;
                              top: 50%;
                              left: 0;
                              width: 100%;
                              height: 0;
                              transform: translateY(-50%);
                              background-color: $text;
                              box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.15);
                              z-index: 1;
                        }
                  }
            }

            .info {
                  position: absolute;
                  top: 0;
                  left: 50%;
                  transform: translate(-50%, -85%);
                  display: inline-block;
                  white-space: nowrap;
                  z-index: 1;
                  font-weight: 500;
                  color: $white;
                  background-color: $red;
                  padding: 8px;
                  border-radius: 4px;
                  text-align: center;
                  font-size: 14px;
            }
      }

      .nav-to {
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
            padding: 16px 0;
            display: flex;
            align-items: center;

            a {
                  margin: 4px;

                  &:first-child,
                  &:last-child {
                        width: 150px;
                  }
            }
      }

      @media screen and (max-width:550px) {
            height: 600px;

            &-object {
                  height: 600px;
            }

            .nav-to {
                  flex-direction: column;
            }

            .marker {
                  &.office {
                        .office-logo {
                              transform: translate(-50%, 0);
                        }
                  }
            }
      }
}



//
//  Single-Post page shortcut
//

.shortcut {
      width: 100%;
      margin-top: 50px;

      .sc-wrapper {
            display: flex;
            flex-wrap: wrap;

            .sc-content {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  justify-content: space-between;
                  background-color: #013692;
                  padding: 46px;
                  border-radius: 16px;
                  -webkit-box-shadow: 0px 0px 30px 0px rgba(56, 56, 56, 0.05);
                  box-shadow: 0px 0px 30px 0px rgba(56, 56, 56, 0.05);
                  overflow: hidden;
                  width: 100%;

                  @media (max-width: 600px) {
                        flex-direction: column;
                  }

                  >a {
                        background-color: #fff;
                        border-color: #fff;
                        color: #013692;

                        &:hover {
                              background-color: #eff5ff;
                              border-color: #eff5ff;
                        }
                  }

                  .sc-text-wrapper {
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        @media (max-width: 600px) {
                              margin-bottom: 25px;
                        }

                        >i {
                              color: #fff;
                              font-size: 36px;
                              margin-right: 15px;

                              @media (max-width: 600px) {
                                    font-size: 28px;
                              }
                        }

                        .sc-text {
                              font-size: 36px;
                              color: #fff;
                              font-weight: bold;

                              @media (max-width: 600px) {
                                    font-size: 28px;
                              }
                        }
                  }
            }
      }
}


//
// Footer sneak-peak
//

.sneak-peak {
      display: flex;
      justify-content: center;
      margin-top: 50px;
      position: relative;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);

      .sp-container {
            display: flex;
            max-width: 1200px;
            width: 100%;
            margin-bottom: 50px;

            .slick-list {
                  min-width: 100%;

                  .slick-track {
                        display: flex !important;

                        .slick-slide {
                              padding: 0 8px;
                              box-sizing: border-box;
                              height: inherit !important;

                              >div {
                                    height: 100%;
                                    padding-bottom: 25px;
                                    padding-right: 5px;
                                    padding-left: 5px;
                              }
                        }
                  }
            }

            .sp-posts-wrapper {
                  border-radius: 15px;
                  height: 100%;
                  display: flex !important;
                  flex-direction: column;
                  position: relative;
                  border: 1px solid rgba(0, 0, 0, 0.2);
                  justify-content: space-between;

                  &:after {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0px;
                        right: 0px;
                        height: 100%;
                        box-shadow: 0px 6px 15px 0px rgba(0, 0, 0, 0.2);
                        border-radius: 15px;
                        opacity: 0;
                        transition: opacity 0.4s ease;
                        z-index: 0;
                  }

                  &:hover,
                  &:focus,
                  &:focus-within {
                        &:after {
                              opacity: 1;
                        }
                  }

                  .sp-post-img {
                        height: 125px;
                        background-size: cover;
                        background-position: center;
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-align: end;
                        -ms-flex-align: end;
                        align-items: flex-end;
                        padding: 16px 24px;
                        color: #FFFFFF;
                        font-size: 20px;
                        position: relative;
                        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
                        border-radius: 15px 15px 0 0;

                        &:after {
                              content: '';
                              position: absolute;
                              bottom: 0;
                              left: 0;
                              width: 100%;
                              height: 40%;
                              background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.8)), to(rgba(0, 0, 0, 0)));
                              background-image: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
                              background-image: -o-linear-gradient(bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
                              background-image: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
                        }

                        .sp-post-date {
                              z-index: 2;
                              font-size: 18px;
                        }
                  }

                  .sp-post-title {
                        font-weight: 900;
                        font-size: 22px;
                        margin-top: 15px;
                        margin-bottom: 15px;
                        padding: 16px 24px;
                  }

                  .sp-post-button {
                        width: 125px;
                        align-self: center;
                        margin-bottom: 25px;

                        &:after {
                              content: '';
                              position: absolute;
                              top: 0;
                              left: 15px;
                              right: 15px;
                              height: 100%;
                              z-index: 3;
                        }
                  }
            }
      }
}


//
// Contact
//
.contact {
      $this: &;

      background-color: $gray;

      .section-title {
            text-align: left;
            color: $blue;
            font-size: 36px;
            margin-bottom: 16px;
      }

      &-wrapper {
            background-color: #FFFFFF;
            box-shadow: $box-shadow;
            border-radius: 15px;
            padding: 32px;
      }

      &-info {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
      }

      &-box {
            flex: 1 50%;
            padding: 16px 16px 16px 0;

            &-title {
                  color: $blue;
                  font-size: 18px;
                  margin-bottom: 8px;

                  i {
                        width: 32px;
                        font-size: 24px;
                        display: inline-block;
                        vertical-align: middle;
                  }
            }

            &-list {
                  padding-left: 36px;

                  span {
                        display: block;

                        &+span {
                              margin-top: 8px;
                        }
                  }

                  br {
                        display: block;
                        margin-bottom: 24px;
                  }
            }
      }

      &-form {}

      &.training {
            .section-title {
                  text-align: center;
            }

            .wpcf7 {
                  width: 100%;
            }
      }

      @media screen and (max-width:768px) {
            &-info {
                  margin-bottom: 32px;
            }
      }
}


//
// Form
//
form {
      .form {
            &-group {
                  display: flex;
                  flex-direction: row;
                  align-items: flex-start;
                  width: 100%;

                  .form-row {
                        width: 50%;

                        &:nth-child(1) {
                              margin-right: 8px;
                        }

                        &:nth-child(2) {
                              margin-left: 8px;
                        }
                  }

                  @media screen and (max-width:660px) {
                        flex-direction: column;

                        .form-row {
                              width: 100%;
                              margin-bottom: 8px;

                              &:nth-child(1) {
                                    margin-right: 0;
                              }

                              &:nth-child(2) {
                                    margin-left: 0;
                              }
                        }
                  }
            }

            &-row {
                  display: flex;
                  align-items: center;
                  position: relative;
                  margin-bottom: 16px;

                  label {
                        position: absolute;
                        top: 13px;
                        left: 16px;
                        opacity: 0.75;
                        pointer-events: none;
                        display: block;

                        &::selection {
                              background-color: none;
                        }
                  }

                  .wpcf7-form-control-wrap {
                        margin-bottom: 0;

                        &.training {
                              padding-bottom: 0;
                        }
                  }

                  &-point {
                        .wpcf7-list-item-label {
                              font-size: 18px;
                              font-weight: 500;
                        }
                  }

                  &.select {
                        flex-direction: column;
                        align-items: flex-start;

                        label {
                              position: relative;
                              top: inital;
                              left: initial;
                        }
                  }

                  &.training-summary {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;

                        border: none;
                        padding: 12px 16px;

                        box-shadow: inset $box-shadow;

                        display: block;
                        width: 100%;

                        border-radius: 10px;

                        background-color: $gray;

                        div+div {
                              margin-top: 8px;
                        }

                        span {
                              color: $blue;
                              font-weight: 500;
                              font-size: 20px;
                        }
                  }

                  &.label-static {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: flex-start;

                        label {
                              position: relative;
                              top: initial;
                              left: initial;
                              pointer-events: all;
                              margin-bottom: 8px;
                              cursor: pointer;
                        }
                  }
            }

            &-control {
                  border: none;

                  font-family: $font;
                  font-size: 16px;

                  padding: 12px 16px;

                  box-shadow: inset $box-shadow;

                  display: block;
                  width: 100%;

                  border-radius: 10px;

                  background-color: $gray;

                  transition: background 0.4s ease;

                  &:hover,
                  &:focus {
                        background-color: $white;
                  }

            }

            &-actions {
                  display: flex;
                  justify-content: center;
                  align-items: center;
            }
      }

      //
      textarea.form-control {
            resize: vertical;
            min-height: 100px;
            height: 250px;
            border-bottom-right-radius: 0;
      }
}

// Selectric
.selectric {
      border: none;

      font-family: $font;
      font-size: 16px;

      padding: 12px 16px;

      box-shadow: inset $box-shadow;

      display: block;
      width: 100%;

      border-radius: 10px;

      background-color: $gray;

      transition: background 0.4s ease;

      &:hover,
      &:focus {
            background-color: $white;
      }

      &-focus {
            .selectric {
                  border-radius: 10px 10px 0 0;
            }
      }

      .label {
            font-family: $font;
            font-size: 16px;
            opacity: 0.75;
            margin: 0;
      }

      .button {
            width: 64px;
            height: 64px;
            background: none;
      }

      &-items {
            border: 1px solid $gray;

            li {
                  font-family: $font;
                  font-size: 16px;
                  padding: 12px 16px;
            }
      }
}

// WP CONTACT FROM 7
.wpcf7 {
      $wpcf7: &;

      &-form {
            &-control {
                  &-wrap {
                        margin-bottom: 16px;

                        display: block;
                        width: 100%;

                        label {
                              position: relative;
                              top: initial;
                              left: initial;
                              right: initial;
                              bottom: initial;

                              display: flex;
                              align-items: center;

                              input {
                                    display: none;
                              }
                        }
                  }
            }
      }

      &-acceptance,
      &-checkbox,
      &-radio {
            display: block;

            #{$wpcf7}-list-item {
                  margin: 0;

                  &-label {
                        position: relative;
                        display: inline-block;
                        padding-left: 32px;
                        cursor: pointer;
                        font-size: 0.85rem;

                        &::before {
                              position: absolute;
                              content: '';
                              top: 50%;
                              left: 0;
                              width: 20px;
                              height: 20px;
                              border-radius: 4px;
                              background-color: #f6f6f6;
                              transition: background-color 0.4s ease;
                              box-shadow: inset 0px 0px 8px 0px rgba(0, 0, 0, 0.07);

                              transform: translate(0, -50%);
                        }

                        &:after {
                              position: absolute;
                              content: '';
                              top: 50%;
                              left: 3px;
                              width: 14px;
                              height: 14px;
                              background-color: $blue;
                              opacity: 0;
                              border-radius: 2px;
                              transition: opacity 0.4s ease;

                              transform: translate(0, -50%);
                        }
                  }

                  input {
                        position: absolute;
                        left: -99999px;
                        opacity: 0 !important;
                        width: 0 !important;
                        height: 0 !important;

                        &:checked {
                              &+#{$wpcf7}-list-item-label {
                                    &:after {
                                          opacity: 1;
                                    }
                              }
                        }
                  }

                  &.hide-label {
                        #{$wpcf7}-list-item-label {
                              display: none;
                        }
                  }
            }
      }

      &-radio {
            #{$wpcf7}-list-item {
                  &-label {

                        &:before,
                        &:after {
                              border-radius: 50%;
                        }
                  }
            }
      }
}

form .wpcf7-acceptance .wpcf7-list-item input:checked+.wpcf7-list-item-label::after,
form .wpcf7-checkbox .wpcf7-list-item input:checked+.wpcf7-list-item-label::after,
form .wpcf7-radio .wpcf7-list-item input:checked+.wpcf7-list-item-label::after {
      opacity: 1;
}


// Newsletter plugin
.tnp {
      &-field {
            margin-bottom: 16px !important;

            label {
                  font-size: 16px !important;
                  margin-bottom: 8px;
            }

            input[type="text"],
            input[type="email"],
            select {
                  border: none !important;

                  font-family: $font !important;
                  font-size: 16px !important;

                  padding: 12px 16px !important;

                  box-shadow: inset $box-shadow !important;

                  display: block !important;
                  width: 100% !important;

                  border-radius: 10px !important;

                  background-color: $gray !important;

                  transition: background 0.4s ease !important;

                  &:hover,
                  &:focus {
                        background-color: $white !important;
                  }
            }

            input[type="submit"] {
                  border: 2px solid $blue !important;
                  color: $white !important;
                  cursor: pointer !important;
                  display: inline-block !important;
                  font-size: 16px !important;
                  // outline: 0 !important;
                  padding: 0.4rem 1rem 0.5rem 1rem !important;
                  text-decoration: none !important;
                  text-align: center !important;
                  transition: background .2s ease, border .2s ease, box-shadow .2s ease, color .2s ease !important;
                  vertical-align: middle !important;
                  white-space: nowrap !important;
                  border-radius: 64px !important;
                  font-weight: 600 !important;
                  background-color: $blue !important;

                  &:hover,
                  &:focus,
                  &.active {
                        background-color: lighten($blue, 68%);
                        border-color: $blue;
                  }
            }
      }
}


//
// About
//
$about_pic_negative: 100;

.about {
      &-header {
            padding-bottom: 0;

            @media screen and (max-width:992px) {
                  padding-bottom: 64px;
            }

            .section-title {
                  margin-bottom: 32px;
                  color: $blue;
            }

            &-pic {
                  display: block;
                  width: auto;
                  max-width: 75%;
                  margin: 0 auto;
                  margin-bottom: - $about_pic_negative + px;
                  box-shadow: $box-shadow;
                  border-radius: 50%;

                  @media screen and (max-width:992px) {
                        margin-bottom: 0;
                  }

                  @media screen and (max-width:768px) {
                        margin-bottom: 24px;
                  }
            }

            &-content {
                  p {
                        font-weight: 100;
                        font-size: 16px;

                        &:first-child {
                              color: $blue;
                              font-weight: 500;
                              font-size: 18px;
                        }

                        &+p {
                              margin-top: 24px;
                        }
                  }
            }
      }

      //
      // About more
      //

      &-more {
            &-grid {
                  display: flex;
                  flex-wrap: wrap;
            }

            &-item {
                  margin-bottom: 32px;

                  .separator {
                        margin-left: 0;
                        margin-top: 16px;
                        margin-bottom: 16px;
                  }
            }

            &-title {
                  font-weight: 600;
                  font-size: 24px;
            }

            &-desc {
                  *+* {
                        margin-top: 16px;
                  }

                  span {
                        display: block;
                  }

                  p:empty {
                        display: none;
                  }
            }
      }
}


//
// Worker
//
.worker {
      background-color: $gray;

      padding-top: $about_pic_negative - 32 + px;

      .section-title {
            color: $blue;
            margin-bottom: 96px;
      }

      &-card {
            position: relative;
            background-color: $white;
            box-shadow: $box-shadow;
            border-radius: 10px;
            display: flex;
            align-items: flex-start;

            padding: 24px 24px 32px 24px;
            margin-bottom: 96px;
            width: 100%;

            @media screen and (max-width:768px) {
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
            }
      }

      &-info {
            width: 30%;

            @media screen and (max-width:768px) {
                  width: 100%;

                  display: flex;
                  justify-content: center;
                  align-items: center;
                  flex-direction: column;
            }
      }

      &-bio {
            width: 70%;
            padding-left: 24px;

            @media screen and (max-width:768px) {
                  width: 100%;
                  padding-left: 0;
            }
      }

      &-image {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            width: 100%;
            height: 100%;
            background-color: #ebebeb;
            border-radius: 50%;

            overflow: hidden;

            margin-top: -64px;

            img {
                  position: absolute;
                  top: 0;
                  left: 0;
                  display: block;
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  object-position: top;
                  border-radius: 50%;
            }

            &:after {
                  content: "";
                  display: block;
                  padding-bottom: 100%;
            }

            @media screen and (max-width:768px) {
                  width: 196px;
                  height: 196px;

                  margin-bottom: 24px;

                  &+.worker-contact {
                        margin-top: 0;
                        margin-bottom: 24px;
                  }
            }
      }

      &-contact {
            margin-top: 24px;

            ul {
                  list-style: none;
                  display: flex;
                  flex-direction: column;
                  align-items: center;

                  li {
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        &+li {
                              margin-top: 16px;
                        }

                        a {
                              display: flex;
                              flex-direction: column;
                              align-items: center;
                        }

                        i {
                              margin-bottom: 8px;
                              color: $blue;
                              font-size: 22px;
                        }
                  }
            }
      }

      &-name {
            font-size: 24px;
            color: $blue;
            font-weight: 600;
            margin-bottom: 16px;

            @media screen and (max-width:768px) {
                  text-align: center;
            }
      }

      &-position {
            font-weight: 600;
            margin-bottom: 24px;

            @media screen and (max-width:768px) {
                  text-align: center;
            }
      }

      &-about {
            p {
                  margin-bottom: 4px;
            }

            &>p+p {
                  margin-top: 16px;
            }

            ul {
                  list-style: none;

                  li {
                        padding-left: 16px;
                        position: relative;

                        &+li {
                              margin-top: 8px;
                        }

                        &:after {
                              content: '';
                              position: absolute;
                              top: 9px;
                              left: 0;
                              width: 5px;
                              height: 5px;
                              border-radius: 50%;
                              background-color: $blue;
                        }
                  }
            }
      }

      &-grid {
            &>div {
                  display: flex;
            }
      }
}


//
// Single post/page
//
.single {
      position: relative;
      background-color: $gray;

      &-header {
            margin-bottom: 64px;
            text-align: center;

            padding: 0 128px;

            .section-title {
                  margin-bottom: 32px;

                  font-size: 36px;
            }
      }

      &-date {
            font-size: 20px;
      }

      &-wrapper {
            background-color: $white;
            padding: 64px;
            border-radius: 16px;
            box-shadow: $box-shadow;

            overflow: hidden;
      }

      &-content {
            display: block;
            line-height: 1.4;
            font-size: 14px;

            &-footer {
                  margin-top: 64px;

                  .top {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                  }

                  .middle {
                        margin: 24px 0;

                        display: flex;
                        align-items: center;
                        justify-content: center;
                        opacity: 0.3;

                        &:after,
                        &:before {
                              content: '';
                              display: inline-block;
                              height: 1px;
                              flex: 1 1 100%;
                              background-color: $text;
                        }

                        p {
                              flex-shrink: 0;
                              padding: 0 16px;
                              margin: -4px 0 0 0;
                        }
                  }

                  .bottom {
                        margin-bottom: -24px;

                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .small-post {
                              display: flex;
                              align-items: center;
                              max-width: 400px;
                              overflow: hidden;

                              .image {
                                    width: 64px;
                                    height: 64px;
                                    border-radius: 50%;
                                    overflow: hidden;
                                    flex-shrink: 0;
                                    position: relative;

                                    &:after {
                                          position: absolute;
                                          top: 0;
                                          left: 0;
                                          width: 100%;
                                          height: 100%;

                                          display: flex;
                                          align-items: center;
                                          justify-content: center;
                                          text-align: center;

                                          font-style: normal;
                                          font-variant: normal;
                                          text-rendering: auto;
                                          -webkit-font-smoothing: antialiased;
                                          font-family: "Font Awesome 5 Free";
                                          font-weight: 900;
                                          background-color: $white;
                                          border: 1px solid $blue;
                                          border-radius: 50%;

                                          opacity: 0;
                                          transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
                                    }

                                    img {
                                          display: block;
                                          width: 100%;
                                          height: 100%;
                                    }
                              }

                              &.next {
                                    margin-right: auto;

                                    .image {
                                          margin-right: 16px;

                                          &:after {
                                                content: "\f060";

                                                transform: rotate(-45deg);
                                          }
                                    }

                                    p {
                                          text-align: left;
                                    }
                              }

                              &.prev {
                                    margin-left: auto;

                                    .image {
                                          margin-left: 16px;

                                          &:after {
                                                content: "\f061";

                                                transform: rotate(45deg);
                                          }
                                    }

                                    p {
                                          text-align: right;
                                    }
                              }

                              &:hover,
                              &:focus {
                                    .image {
                                          &:after {
                                                opacity: 1;
                                                transform: rotate(0deg);
                                          }
                                    }
                              }
                        }
                  }
            }

            ul:not(.square),
            ol:not(.square) {
                  list-style: none;

                  li {
                        position: relative;
                        padding-left: 20px;

                        &:after {
                              content: '';
                              position: absolute;
                              top: 8px;
                              left: 0;
                              width: 6px;
                              height: 6px;
                              background-color: $blue;
                        }

                        ul,
                        ol {
                              li {
                                    &:after {
                                          background: none;
                                          border: 1px solid $blue;
                                    }
                              }
                        }
                  }
            }

            a {
                  &[data-fancybox] {
                        display: block;
                  }
            }

            img {
                  &:not(.banner-img) {
                        display: block;
                        max-width: 75%;
                        margin-left: auto;
                        margin-right: auto;
                        height: auto;
                  }
            }

            &>* {
                  &+* {
                        margin-top: 16px;
                  }
            }

            table {
                  width: 100%;
                  border-width: 1px;
                  border-spacing: 0;
                  border-collapse: collapse;

                  tr {
                        td {
                              position: relative;
                              padding: 8px;
                              border: 1px solid transparentize($black, 0.90);

                              &:empty {
                                    padding: 0;
                              }

                              &:before {
                                    display: none;
                                    content: attr(data-title);

                                    padding: 8px 0 8px 0;
                                    margin: -8px 8px -8px 0;
                                    width: 30%;
                                    min-width: 100px;
                                    border-right: 1px solid rgba(0, 0, 0, 0.1);
                              }
                        }
                  }

                  @media screen and (max-width:840px) {
                        width: 100%;

                        tr {
                              &:nth-child(odd) {
                                    background-color: $gray;
                              }

                              &:first-child {
                                    display: none;
                              }

                              td {
                                    display: flex;
                                    align-items: stretch;
                                    width: 100%;

                                    &:before {
                                          display: block;
                                    }
                              }
                        }
                  }

                  @media screen and (max-width:500px) {
                        tr {

                              td {
                                    display: flex;
                                    align-items: stretch;
                                    width: 100%;

                                    &:before {
                                          display: block;
                                          width: 50%;
                                          min-width: 50%;
                                    }
                              }
                        }
                  }
            }

            .banner {
                  display: block;
                  overflow: hidden;
                  border-radius: 10px;
                  width: 100%;

                  a {
                        display: block;
                  }

                  &-img {
                        display: block;
                        width: 100%;
                        max-width: 100%;
                        height: auto;
                  }
            }

            &>*+.banner,
            &>.banner+* {
                  margin-top: 64px;

                  @media screen and (max-width:648px) {
                        margin-top: 32px;
                  }
            }
      }

      &-thumbnail {
            width: 500px;
            float: right;
            padding: 0 0 16px 16px;

            img {
                  display: block;
                  width: 100%;
                  max-width: 100%;
                  height: auto;
                  margin-left: 0;
                  margin-right: 0;

                  border-radius: 16px;
                  overflow: hidden;
            }

            @media screen and (max-width:896px) {
                  padding: 0;
                  float: none;
                  width: 100%;

                  img {
                        max-width: 50% !important;
                  }
            }

            @media screen and (max-width:684px) {
                  img {
                        max-width: 100% !important;
                  }
            }
      }

      &.flat {
            padding-top: 128px;
            margin-top: -128px;

            z-index: 1;
      }

      @media screen and (max-width:680px){
            &-header{
                  padding: 0 24px;

                  .section-title{
                        font-size: 40px;
                  }
            }

            &-wrapper{
                  padding: 24px;
            }

            &-content{
                  &-footer{
                        .top{
                              flex-direction: column;
                              align-items: flex-end;

                              .btn + .btn{
                                    margin-top: 16px;
                              }
                        }

                        .bottom{
                              align-items: flex-start;
                              margin-bottom: 24px;

                              .small-post{
                                    max-width: initial;
                                    width: 100%;
                                    text-align: center;

                                    &.next{
                                          flex-direction: column;

                                          .image{
                                                margin-right: 0;
                                          }

                                          p{
                                                text-align: center;
                                          }
                                    }

                                    &.prev{
                                          flex-direction: column-reverse;

                                          .image{
                                                margin-left: 0;
                                          }

                                          p{
                                                text-align: center;
                                          }
                                    }
                              }
                        }
                  }
            }
      }

      @media screen and (max-width:400px){
            &-content{
                  &-footer{
                        .bottom{
                              flex-direction: column;
                              align-items: flex-start;
                              margin-bottom: 24px;

                              .small-post{
                                    max-width: initial;
                                    width: 100%;
                                    text-align: center;

                                    &.next{
                                          flex-direction: column;
                                          margin-bottom: 24px;

                                          .image{
                                                margin-right: 0;
                                                display: none;
                                          }

                                          p{
                                                text-align: center;
                                          }
                                    }

                                    &.prev{
                                          flex-direction: column-reverse;

                                          .image{
                                                margin-left: 0;
                                                display: none;
                                          }

                                          p{
                                                text-align: center;
                                          }
                                    }
                              }
                        }
                  }
            }
      }
}


//
// Product
//
.product {
      &-header {
            padding-bottom: 0;

            .row {
                  // align-items: center;
            }

            @media screen and (max-width:992px) {
                  padding-bottom: 64px;
            }

            .section-title {
                  margin-bottom: 32px;
                  color: $blue;
            }

            &-pic {
                  display: block;
                  width: auto;
                  max-width: 50%;
                  margin: 0 auto;
                  margin-bottom: - $about_pic_negative + px;
                  // box-shadow: $box-shadow;
                  // border-radius: 50%;

                  @media screen and (max-width:992px) {
                        margin-bottom: 0;
                  }

                  @media screen and (max-width:768px) {
                        margin-top: 24px;
                  }
            }
      }

      &-price {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;

            p {
                  margin: 0 24px;
            }

            span {
                  font-size: 24px;
                  font-weight: 900;
                  color: $blue;
            }

            &.column {
                  display: block;

                  font-size: 20px;

                  span {
                        font-size: 50px;
                  }
            }

            &-section {
                  .text-center {
                        margin-top: 32px;
                  }
            }

            @media screen and (max-width:768px) {
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  text-align: center;
            }
      }

      &-about {
            background-color: $gray;
            padding-top: 128px;
            font-size: 17px;

            &-inner {
                  &>*+* {
                        margin-top: 16px;
                  }
            }
      }
}


//
// Ubezpieczenia
//
.coopcompany {
      .section {
            &-title {
                  i {
                        color: $yellow;
                  }
            }
      }

      &-grid {
            .gallery {
                  margin-top: 0 !important;

                  &-item {
                        flex: 1 1 12.5% !important;

                        @media screen and (max-width:750px) {
                              flex: 1 1 25% !important;
                        }

                        @media screen and (max-width:450px) {
                              flex: 1 1 33% !important;
                        }

                        @media screen and (max-width:320px) {
                              flex: 1 1 50% !important;
                        }
                  }
            }
      }
}


//
// Footer
//
.footer {
      &-top {
            padding: 64px 0;
      }

      &-bottom {
            padding: 32px 0;
            background-color: $gray;

            .row {
                  align-items: center;
            }

            @media screen and (max-width:992px) {
                  padding: 32px 0 114px 0;
            }
      }

      &-title {
            color: $blue;
            font-size: 24px;
            font-weight: 800;
            margin-bottom: 16px;

            &.big {
                  font-size: 36px;
                  margin-bottom: 0;
            }
      }

      &-content {
            p {
                  &+p {
                        margin-top: 8px;
                  }
            }
      }

      &-menu {
            display: flex;
            align-items: center;
            list-style: none;

            li {
                  &+li {
                        margin-left: 16px;
                  }
            }
      }

      .logo {
            display: block;

            img {
                  display: block;
                  width: 100%;
            }
      }

      .createby {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            img {
                  margin-left: 8px;
            }
      }

      &-navigation {
            display: none;

            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            background-color: $blue;
            border-top: 6px solid $red;
            box-shadow: 0 -5px 10px rgba(41, 41, 41, 0.15);
            z-index: 10;
            padding: 16px 0;

            ul {
                  list-style: none;
                  display: flex;
                  align-items: flex-start;

                  li {
                        flex: 1 1 33.33%;

                        a {
                              display: flex;
                              flex-direction: column;
                              align-items: center;
                              justify-content: center;
                              text-align: center;
                              color: $white;
                              opacity: 0.5;

                              transition: all 0.4s ease-in-out;

                              &:hover,
                              &.active {
                                    opacity: 1;
                              }

                              .icon {
                                    font-size: 24px;
                                    margin-bottom: 8px;
                                    height: 24px;
                                    line-height: 1;

                                    i+i {
                                          display: none;
                                    }
                              }

                              .name {
                                    font-size: 12px;
                              }

                              &[data-action="toggle_menu"] {

                                    .icon,
                                    .name {

                                          i,
                                          span {
                                                &:nth-child(1) {
                                                      display: block;
                                                }

                                                &:nth-child(2) {
                                                      display: none;
                                                }
                                          }
                                    }

                                    &.active {

                                          .icon,
                                          .name {

                                                i,
                                                span {
                                                      &:nth-child(1) {
                                                            display: none;
                                                      }

                                                      &:nth-child(2) {
                                                            display: block;
                                                      }
                                                }
                                          }
                                    }
                              }
                        }
                  }
            }

            @media screen and (max-width:600px) {
                  display: block;
            }
      }

      @media screen and (max-width:992px) {
            &-col {
                  &+& {
                        margin-top: 16px;
                  }
            }

            &-title {
                  text-align: center;
            }

            &-content {
                  p {
                        text-align: center;
                  }
            }

            &-menu {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  list-style: none;

                  li {
                        &+li {
                              margin-left: 16px;
                        }
                  }
            }

            .logo {
                  img {
                        margin: 0 auto;
                        width: 200px;
                  }
            }

            .createby {
                  justify-content: center;
            }
      }
}


//
// Services pages
//
.service {
      $this: &;

      &-content {
            &-inner {
                  &>*+* {
                        margin-top: 16px;
                  }
            }
      }

      &-icon-box {
            .icon {
                  font-size: 64px;
                  margin-bottom: 16px;
            }

            h4 {
                  font-size: 28px;
                  margin-bottom: 24px;
            }

            &.blue {
                  .icon {
                        color: $blue;
                  }
            }

            &.red {
                  .icon {
                        color: $red;
                  }
            }

            &.green {
                  .icon {
                        color: $green;
                  }
            }

            &.yellow {
                  .icon {
                        color: $yellow;
                  }
            }

            &.violet {
                  .icon {
                        color: $violet;
                  }
            }

            &.white {
                  .icon {
                        color: $white;
                  }
            }

            &.black {
                  .icon {
                        color: $text;
                  }
            }
      }

      // Header
      &-header {
            position: relative;
            z-index: 2;

            &-inner {
                  position: relative;
                  border-radius: 15px;
                  overflow: hidden;
            }

            &-content {
                  position: relative;
                  z-index: 1;
                  padding: 48px 128px;
                  text-align: center;
                  color: $white;

                  h1 {
                        font-size: 38px;

                        img {
                              display: inline-block;
                              vertical-align: middle;
                              margin-top: -6px;
                        }
                  }

                  p {
                        font-size: 18px;
                  }

                  &>*+* {
                        margin-top: 16px;
                  }

                  @media screen and (max-width:720px) {
                        padding: 48px 24px;
                  }
            }

            &-background {
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  z-index: 0;
                  background-size: cover;
                  background-position: center;
                  background-repeat: no-repeat;
                  margin: 0;
                  opacity: 0.5;
                  filter: grayscale(100%);
            }

            &.blue {
                  #{$this}-header-inner {
                        background-color: $blue;
                  }
            }

            &.green {
                  #{$this}-header-inner {
                        background-color: $green;
                  }
            }

            &.red {
                  #{$this}-header-inner {
                        background-color: $red;
                  }
            }

            &.yellow {
                  #{$this}-header-inner {
                        background-color: $yellow;
                  }
            }

            &.violet {
                  #{$this}-header-inner {
                        background-color: $violet;
                  }
            }

            &.white {
                  #{$this}-header-inner {
                        background-color: $white;
                  }

                  #{$this}-header-content {
                        color: $text;
                  }
            }

            &.black {
                  #{$this}-header-inner {
                        background-color: $text;
                  }
            }
      }

      // Biuro rachunkowe
      // Offer
      &-offer {
            padding-top: 0;

            .offer-box {
                  .row {
                        flex-direction: row;
                  }
            }
      }

      // specialty banner
      &-specialty {
            position: relative;
            z-index: 1;
      }

      // Trust section
      &-trust {
            position: relative;
            color: $white;
            padding-bottom: 96px;

            &:after {
                  content: '';
                  position: absolute;
                  left: 50%;
                  bottom: 0;
                  transform: translate(-50%, 40%) rotate(45deg);
                  width: 32px;
                  height: 32px;
                  border-radius: 5px;
                  background-color: $blue;
                  z-index: 10;
            }

            &.bg-green {
                  &:after {
                        background-color: $green;
                  }
            }
      }

      // Pricing
      &-pricing {
            .container {
                  .row {
                        &:nth-child(2) {
                              [class^="col-"] {
                                    &:not(.featured) {
                                          padding-top: 24px;
                                          padding-bottom: 24px;
                                    }

                                    &.featured {
                                          .pricing-card {
                                                box-shadow: $box-shadow, $box-shadow, $box-shadow, $box-shadow;
                                          }
                                    }
                              }
                        }
                  }
            }

            .pricing {
                  &-card {
                        background-color: $white;
                        border-radius: 15px;
                        box-shadow: $box-shadow, $box-shadow;
                        padding: 24px;
                        text-align: center;
                        height: 100%;

                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;

                        font-weight: 100;

                        .separator {
                              display: block;
                              width: 100%;
                              height: 4px;
                              background-color: $gray;
                              margin: 16px 0;
                        }
                  }

                  &-title {
                        font-weight: 900;
                        font-size: 20px;
                  }

                  &-subtitle {
                        font-weight: 500;
                        font-size: 16px;
                  }

                  &-amount {
                        .price {
                              font-weight: 900;
                              color: $blue;
                              font-size: 64px;
                              line-height: 1;
                              margin: 0 0 8px 0;
                        }
                  }

                  &-list {
                        ul {
                              list-style: none;

                              li {
                                    &+li {
                                          margin-top: 8px;
                                    }
                              }
                        }
                  }
            }

            &-cta {
                  padding-top: 64px;
            }

            &.pricing-table {
                  margin-top: -128px;
                  padding-top: 128px;
            }
      }

      // Files
      &-files {
            &-grid {
                  display: flex;
                  flex-wrap: wrap;

                  .file {
                        &-item {
                              flex: 1 1 50%;
                              margin-bottom: 8px;
                              max-width: 50%;

                              display: flex;
                              align-items: center;

                              position: relative;

                              &:nth-child(odd) {
                                    padding-right: 8px;
                              }

                              &:nth-child(even) {
                                    padding-left: 8px;
                              }
                        }

                        &-icon {
                              font-size: 40px;
                              color: $blue;
                              margin-right: 16px;
                        }

                        &-name {
                              font-weight: 300;

                              a {
                                    font-weight: 900;

                                    &:after {
                                          content: '';
                                          position: absolute;
                                          top: 0;
                                          left: 0;
                                          width: 100%;
                                          height: 100%;
                                    }
                              }
                        }
                  }
            }

            @media screen and (max-width:820px) {
                  &-grid {
                        .file {
                              &-item {
                                    flex: 1 1 100%;
                                    max-width: 100%;

                                    &:nth-child(odd),
                                    &:nth-child(even) {
                                          padding-left: 0;
                                          padding-right: 0;
                                    }

                                    &+.file-item {
                                          margin-top: 16px;
                                    }
                              }
                        }
                  }
            }
      }

      // Czas pracy kierowcy
      // Zakres czasu pracy
      &-scope {
            padding-bottom: 96px;

            &-list {
                  display: flex;
                  flex-direction: column;
            }

            &-item {
                  display: flex;
                  align-items: stretch;
                  margin-bottom: 16px;

                  .icon {
                        width: 44px;
                        min-width: 44px;
                        font-size: 24px;

                        display: flex;
                        align-items: flex-start;
                  }

                  .content {
                        height: 100%;

                        display: flex;
                        align-items: center;
                  }
            }
      }

      &-about-report {

            .section-title {
                  font-size: 32px;
                  margin-bottom: 0;
            }

            .separator {
                  margin-top: 20px;
                  margin-bottom: 20px;
            }

            .text-center {
                  font-size: 20px;
            }

            &-image {
                  margin-top: -96px;

                  @media screen and (max-width:1350px) {
                        width: 100%;
                  }

                  @media screen and (max-width:1024px) {
                        margin-top: 32px;
                  }

                  @media screen and (max-width:992px) {
                        width: 75%;
                        display: block;
                        margin-left: auto;
                        margin-right: auto;
                  }
            }

            .service-files-grid {
                  margin-top: 32px;

                  .file-item {
                        margin-bottom: 16px;
                  }
            }

            .btn {
                  @media screen and (max-width:575px) {
                        margin-top: 24px;
                  }
            }
      }

      &-about-cpk {
            .service-content-inner {
                  ul {
                        li {
                              &+li {
                                    margin-top: 8px;
                              }
                        }
                  }
            }

            &-boxes {
                  margin-top: 64px;
            }
      }

      &-payroll {
            // padding-top: 192px;
            // background-color: $gray;

            .flag {
                  &-grid {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;

                        list-style: none;
                  }

                  &-item {
                        flex: 1 1 25%;
                        margin-bottom: 32px;

                        display: flex;
                        align-items: center;

                        @media screen and (max-width:687px) {
                              flex: 1 1 50%;

                              justify-content: center;
                        }

                        @media screen and (max-width:486px) {
                              justify-content: flex-start;
                        }

                        @media screen and (max-width:360px) {
                              flex: 1 1 100%;
                        }
                  }

                  &-ico {
                        margin-right: 16px;
                        box-shadow: $box-shadow;
                        border-radius: 50%;

                        img {
                              display: block;
                              width: 32px;
                              height: 32px;
                        }
                  }

                  &-name {
                        font-size: 24px;
                  }
            }
      }
}



//
// Button
//
.btn {
      border: 2px solid $blue;
      color: $blue;
      cursor: pointer;
      display: inline-block;
      font-size: 16px;
      // outline: 0;
      padding: 0.4rem 1rem 0.5rem 1rem;
      text-decoration: none;
      text-align: center;
      transition: background .2s ease, border .2s ease, box-shadow .2s ease, color .2s ease;
      vertical-align: middle;
      white-space: nowrap;
      border-radius: 64px;
      font-weight: 600;
      background-color: transparent;

      &.shadow {
            box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.12);
      }

      .icon {
            width: 20px;
            display: inline-block;
            text-align: center;

            i {
                  display: inline-block;
                  vertical-align: middle;
                  font-size: 18px;
                  margin-bottom: 2px;
            }
      }

      &:hover,
      &:focus,
      &.active {
            background-color: lighten($blue, 68%);
            border-color: $blue;
      }

      &.btn-primary {
            background-color: $blue;
            border-color: $blue;
            color: $white;

            &:hover,
            &:focus,
            &.active {
                  background-color: $blue-l;
                  border-color: $blue-l;
                  border-color: $blue-l;
            }
      }

      &.btn-link {
            background: 0 0;
            border-color: transparent;
            color: $blue;
            font-weight: 500;

            &:hover,
            &:focus,
            &.active {
                  background-color: transparent;
                  color: $blue-l;
            }
      }

      &.red {
            border-color: $red;
            color: $red;

            &:hover,
            &:focus,
            &.active {
                  background-color: lighten($red, 52%);
                  border-color: $red;
            }

            &.btn-primary {
                  background-color: $red;
                  border-color: $red;
                  color: $white;

                  &:hover,
                  &:focus,
                  &.active {
                        background-color: lighten($red, 10%);
                        border-color: lighten($red, 10%);
                  }
            }

            &.btn-link {
                  background: 0 0;
                  border-color: transparent;
                  color: $red;

                  &:hover,
                  &:focus,
                  &.active {
                        background-color: transparent;
                        color: $red-l;
                  }
            }
      }

      &.green {
            border-color: $green;
            color: $green;

            &:hover,
            &:focus,
            &.active {
                  background-color: lighten($green, 70%);
                  border-color: $green;
            }

            &.btn-primary {
                  background-color: $green;
                  border-color: $green;
                  color: $white;

                  &:hover,
                  &:focus,
                  &.active {
                        background-color: lighten($green, 10%);
                        border-color: lighten($green, 10%);
                  }
            }

            &.btn-link {
                  background: 0 0;
                  border-color: transparent;
                  color: $green;

                  &:hover,
                  &:focus,
                  &.active {
                        background-color: transparent;
                        color: $green-l;
                  }
            }
      }

      &.yellow {
            border-color: $yellow;
            color: $yellow;

            &:hover,
            &:focus,
            &.active {
                  background-color: lighten($yellow, 50%);
                  border-color: $yellow
            }

            &.btn-primary {
                  background-color: $yellow;
                  border-color: $yellow;
                  color: $white;

                  &:hover,
                  &:focus,
                  &.active {
                        background-color: lighten($yellow, 10%);
                        border-color: lighten($yellow, 10%);
                  }
            }

            &.btn-link {
                  background: 0 0;
                  border-color: transparent;
                  color: $yellow;

                  &:hover,
                  &:focus,
                  &.active {
                        background-color: transparent;
                        color: $yellow-l;
                  }
            }
      }

      &.violet {
            border-color: $violet;
            color: $violet;

            &:hover,
            &:focus,
            &.active {
                  background-color: lighten($violet, 50%);
                  border-color: $violet;
            }

            &.btn-primary {
                  background-color: $violet;
                  border-color: $violet;
                  color: $white;

                  &:hover,
                  &:focus,
                  &.active {
                        background-color: lighten($violet, 10%);
                        border-color: lighten($violet, 10%);
                  }
            }

            &.btn-link {
                  background: 0 0;
                  border-color: transparent;
                  color: $violet;

                  &:hover,
                  &:focus,
                  &.active {
                        background-color: transparent;
                        color: $violet-l;
                  }
            }
      }

      &.black {
            border-color: $text;
            color: $text;

            &:hover,
            &:focus,
            &.active {
                  background-color: lighten($text, 75%);
                  border-color: $text;
            }

            &.btn-primary {
                  background-color: $text;
                  border-color: $text;
                  color: $white;

                  &:hover,
                  &:focus,
                  &.active {
                        background-color: lighten($text, 10%);
                        border-color: lighten($text, 10%);
                  }
            }

            &.btn-link {
                  background: 0 0;
                  border-color: transparent;
                  color: $text;

                  &:hover,
                  &:focus,
                  &.active {
                        background-color: transparent;
                        color: $blue;
                  }
            }
      }

      &.white {
            border-color: $white;
            color: $white;

            &:hover,
            &:focus,
            &.active {
                  background-color: lighten($white, 75%);
                  border-color: $white;
            }

            &.btn-primary {
                  background-color: $white;
                  border-color: $white;

                  &.blue {
                        color: $blue;
                  }

                  &.red {
                        color: $red;
                  }

                  &.green {
                        color: $green;
                  }

                  &.yellow {
                        color: $yellow;
                  }

                  &.violet {
                        color: $violet;
                  }

                  &.black {
                        color: $text
                  }

                  &:hover,
                  &:focus,
                  &.active {
                        background-color: darken($white, 10%);
                        border-color: darken($white, 10%);
                  }
            }

            &.btn-link {
                  background: 0 0;
                  border-color: transparent;
                  color: $text;

                  &:hover,
                  &:focus,
                  &.active {
                        background-color: transparent;
                        color: $text;
                  }
            }
      }

      &.btn-lg {
            font-size: 20px;
            // padding: 0.65rem 1rem;
      }

      &.btn-sm {
            font-size: 14px;
            // padding: 0.35rem 1rem;
      }
}

//
// Form
//
.form-input {
      position: relative;
      display: block;
      font-size: 18px;
      font-family: $font;
      color: $text;
      padding: 4px 16px;
      border: 1px solid $gray-dark
}


//
// WordPress core
//
.gallery {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 32px -16px 0 -16px !important;

      a {
            display: block;

            img {
                  display: block;
                  width: 100%;
                  height: auto;
                  border: none !important;
                  border-radius: 10px;
            }
      }

      &-item {
            float: initial !important;
            margin: 0 !important;
            width: auto !important;
            padding: 0 16px;
      }

      &-icon {}

      &-columns {

            &-1,
            &-7,
            &-8,
            &-9,
            &-10 {
                  .gallery-item {
                        flex: 1 1 100%;
                  }
            }

            &-2 {
                  .gallery-item {
                        flex: 1 1 50%;
                  }
            }

            &-3 {
                  .gallery-item {
                        flex: 1 1 33.33%;
                  }
            }

            &-4 {
                  .gallery-item {
                        flex: 1 1 25%;
                  }
            }

            &-5 {
                  .gallery-item {
                        flex: 1 1 20%;
                  }
            }

            &-6 {
                  .gallery-item {
                        flex: 1 1 16%;
                  }
            }
      }

      &.slick-slider {
            display: block;
            width: 100%;
            position: relative;

            margin: 32px 0 0 0 !important;

            .slick-list {
                  width: 100%;

                  a {
                        img {
                              display: block;
                              width: 100%;
                        }
                  }
            }

            .slick-list {
                  margin: 0 -5px;
            }

            .slick-slide>div {
                  padding: 0 5px;
            }

            .slick-track {
                  a {
                        border: 1px solid $gray-dark;
                        border-radius: 10px;
                        overflow: hidden;

                        img {
                              transform: scale(1);
                              transition: transform 0.2s ease;
                        }

                        &:hover,
                        &:focus {
                              img {
                                    transform: scale(1.1);
                              }
                        }
                  }
            }

            .slick-arrow {
                  font-size: 24px;
                  color: $blue;
                  width: 32px;
                  height: 32px;

                  display: flex;
                  align-items: center;
                  justify-content: center;
                  text-align: center;
                  top: 50%;

                  &.slick-disabled {
                        opacity: 0.5;
                        color: $text;
                        pointer-events: none;
                  }

                  &.slick-prev {
                        left: 0;
                        transform: translate(-100%, -50%);
                  }

                  &.slick-next {
                        right: 0;
                        transform: translate(100%, -50%);
                  }

                  &:before,
                  &:after {
                        display: none;
                  }
            }

            .slick-dots {
                  bottom: -20px;

                  li {
                        padding: 0;
                        width: 32px;
                        height: 4px;

                        button {
                              width: 32px;
                              height: 4px;
                              border-radius: 24px;
                              background-color: $text;
                              padding: 0;
                              opacity: 0.5;

                              &:before {
                                    display: none;
                              }
                        }

                        &.slick-active {
                              button {
                                    opacity: 1;
                              }
                        }

                        &:after {
                              display: none;
                        }
                  }
            }

            @media screen and (max-width: 948px) {
                  margin-bottom: 32px !important;
            }
      }
}

//
// Modal
//
.modal {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 99999;
      background-color: rgba(0, 0, 0, 0.75);

      overflow: hidden;
      overflow-y: auto;

      visibility: hidden;
      opacity: 0;

      transition: all 0.4s ease-in-out;

      &.show {
            visibility: visible;
            opacity: 1;
      }

      &-inner {
            position: relative;
            background-color: $white;
            border-radius: 15px;
            padding: 32px;

            margin: 8em 0;
            overflow: hidden;
      }

      &-title {
            font-size: 44px;
            color: $blue;
            font-weight: 600;
            text-align: center;

            margin-bottom: 24px;
      }

      &-subtitle {
            color: $blue;
            text-align: center;
            font-size: 20px;

            margin-bottom: 24px;
      }

      &-title+&-subtitle {
            margin-top: -24px;
      }

      &-close {
            position: absolute;
            top: 0;
            right: 0;
            width: 64px;
            height: 64px;

            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;

            border: 0;
            background: none;
            font-size: 24px;
            opacity: 0.5;
            transition: opacity 0.4s ease-in-out;

            cursor: pointer;

            &:hover,
            &:focus {
                  opacity: 1;
            }
      }
}


/* =WordPress Core
-------------------------------------------------------------- */
.alignnone {
      margin: 5px 20px 20px 0;
}

.aligncenter,
div.aligncenter {
      display: block;
      margin: 5px auto 5px auto;
}

.alignright {
      float: right;
      margin: 5px 0 20px 20px;
}

.alignleft {
      float: left;
      margin: 5px 20px 20px 0;
}

a img.alignright {
      float: right;
      margin: 5px 0 20px 20px;
}

a img.alignnone {
      margin: 5px 20px 20px 0;
}

a img.alignleft {
      float: left;
      margin: 5px 20px 20px 0;
}

a img.aligncenter {
      display: block;
      margin-left: auto;
      margin-right: auto;
}

.wp-caption {
      background: #fff;
      border: 1px solid #f0f0f0;
      max-width: 96%;
      /* Image does not overflow the content area */
      padding: 5px 3px 10px;
      text-align: center;
}

.wp-caption.alignnone {
      margin: 5px 20px 20px 0;
}

.wp-caption.alignleft {
      margin: 5px 20px 20px 0;
}

.wp-caption.alignright {
      margin: 5px 0 20px 20px;
}

.wp-caption img {
      border: 0 none;
      height: auto;
      margin: 0;
      max-width: 98.5%;
      padding: 0;
      width: auto;
}

.wp-caption p.wp-caption-text {
      font-size: 11px;
      line-height: 17px;
      margin: 0;
      padding: 0 4px 5px;
}

/* Text meant only for screen readers. */
.screen-reader-text {
      border: 0;
      clip: rect(1px, 1px, 1px, 1px);
      clip-path: inset(50%);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute !important;
      width: 1px;
      word-wrap: normal !important;
      /* Many screen reader and browser combinations announce broken words as they would appear visually. */
}

.screen-reader-text:focus {
      background-color: #eee;
      clip: auto !important;
      clip-path: none;
      color: #444;
      display: block;
      font-size: 1em;
      height: auto;
      left: 5px;
      line-height: normal;
      padding: 15px 23px 14px;
      text-decoration: none;
      top: 5px;
      width: auto;
      z-index: 100000;
      /* Above WP toolbar. */
}
